import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Group 24415",
  viewBox: "0 0 22.349 18.797"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<defs><clipPath id=\"a\"><rect width=\"100%\" height=\"100%\" fill=\"currentColor\" data-name=\"Rectangle 12788\"></rect></clipPath></defs><g fill=\"currentColor\" clip-path=\"url(#a)\" data-name=\"Group 24414\"><path d=\"m18.9 1.733-.007.014v-.018A16.66 16.66 0 0 0 11.207 0a16.66 16.66 0 0 0-7.694 1.73C1.214 2.971 0 4.578 0 6.382v5.985c0 1.818 1.239 3.486 3.5 4.7a16.6 16.6 0 0 0 7.684 1.726h.321a16.6 16.6 0 0 0 7.37-1.733c2.241-1.209 3.475-2.878 3.475-4.7V6.43c.005-1.825-1.219-3.494-3.446-4.7m-.815 7.824a14.8 14.8 0 0 1-6.874 1.518 14.76 14.76 0 0 1-6.847-1.511c-1.641-.884-2.546-2-2.546-3.127s.9-2.249 2.531-3.133a14.7 14.7 0 0 1 6.854-1.518 14.6 14.6 0 0 1 6.839 1.543c1.645.883 2.587 2.031 2.587 3.155v.145a4.5 4.5 0 0 1-2.55 2.931m.812 1.578a7.8 7.8 0 0 0 1.733-1.161v2.536c-.073 1.08-.977 2.134-2.555 2.979a14.76 14.76 0 0 1-6.87 1.519 14.9 14.9 0 0 1-6.894-1.515c-1.675-.9-2.6-2.009-2.6-3.126V9.906a15 15 0 0 0 1.763 1.229 16.66 16.66 0 0 0 7.706 1.726 16.8 16.8 0 0 0 7.722-1.726\" data-name=\"Path 6798\"></path><path d=\"M16.1 3.9h-.008a11.2 11.2 0 0 0-4.769-1 11.15 11.15 0 0 0-4.8.988c-1.35.669-2.094 1.588-2.094 2.588S5.173 8.4 6.536 9.072a11.1 11.1 0 0 0 4.523.97h.26a11.1 11.1 0 0 0 4.775-.976 3.55 3.55 0 0 0 2.11-2.3l.008-.267c.013-.976-.757-1.922-2.114-2.6m.38 2.576-.009.132a2.35 2.35 0 0 1-1.481 1.439 9.4 9.4 0 0 1-3.8.7 9.5 9.5 0 0 1-3.793-.7C6.428 7.61 5.9 7.055 5.9 6.478s.528-1.137 1.479-1.562a9.35 9.35 0 0 1 3.81-.708 9.6 9.6 0 0 1 3.782.715c.945.425 1.51 1.012 1.51 1.555\" data-name=\"Path 6799\"></path></g>", 2)
  ])))
}
export default { render: render }